import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`22nd August 2023`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`GraalVM Support`}</strong>{`: Armeria now provides `}<a parentName="p" {...{
            "href": "https://www.graalvm.org/"
          }}>{`GraalVM`}</a>{`
`}<a parentName="p" {...{
            "href": "https://www.graalvm.org/latest/reference-manual/native-image/metadata/"
          }}>{`reachability metadata`}</a>{` to easily build
`}<a parentName="p" {...{
            "href": "https://www.graalvm.org/"
          }}>{`GraalVM`}</a>{` native images. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5005"
          }}>{`#5005`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Micrometer Observation Support`}</strong>{`: Support for `}<a parentName="p" {...{
            "href": "https://micrometer.io/docs/observation"
          }}>{`Micrometer Observation`}</a>{` is added.
Refer to `}<a parentName="p" {...{
            "href": "type://ObservationClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/observation/ObservationClient.html"
          }}>{`type://ObservationClient`}</a>{` or `}<a parentName="p" {...{
            "href": "type://ObservationService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/observation/ObservationService.html"
          }}>{`type://ObservationService`}</a>{` for details on how to integrate with Armeria.  `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4659"
          }}>{`#4659`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4980"
          }}>{`#4980`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ObservationRegistry observationRegistry = ...
WebClient.builder()
  .decorator(ObservationClient.newDecorator(observationRegistry))
...
Server.builder()
  .decorator(ObservationService.newDecorator(observationRegistry))
...
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`WebSocket Client Support`}</strong>{`: You can now send and receive data over `}<a parentName="p" {...{
            "href": "https://en.wikipedia.org/wiki/WebSocket"
          }}>{`WebSocket`}</a>{`
using `}<a parentName="p" {...{
            "href": "type://WebSocketClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/websocket/WebSocketClient.html"
          }}>{`type://WebSocketClient`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4972"
          }}>{`#4972`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`WebSocketClient client = WebSocketClient.of("ws://...");
client.connect("/").thenAccept(webSocketSession -> {
  WebSocketWriter writer = WebSocket.streaming();
  webSocketSessions.setOutbound(writer);
  outbound.write("Hello!");

  Subscriber<WebSocketFrame> subscriber = new Subscriber<WebSocketFrame>() {
    ...
  }
  webSocketSessions.inbound().subscribe(subscriber);
});
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Implement gRPC Richer Error Model More Easily`}</strong>{`: You can now easily use gRPC
`}<a parentName="p" {...{
            "href": "https://grpc.io/docs/guides/error/#richer-error-model"
          }}>{`Richer Error Model`}</a>{` via `}<a parentName="p" {...{
            "href": "type://GoogleGrpcStatusFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/GoogleGrpcStatusFunction.html"
          }}>{`type://GoogleGrpcStatusFunction`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4614"
          }}>{`#4614`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4986"
          }}>{`#4986`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`GoogleGrpcStatusFunction statusFunction = (ctx, throwable, metadata) -> {
  if (throwable instanceof MyException) {
    return com.google.rpc.Status.newBuilder()
      .setCode(Code.UNAUTHENTICATED.getNumber())
      .addDetails(detail(throwable))
      .build();
  }
  ...
};
Server.builder().service(
  GrpcService.builder()
    .exceptionMapping(statusFunction))
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Set HTTP Trailers Easily`}</strong>{`: You can now easily set trailers to be sent after the data stream using
`}<a parentName="p" {...{
            "href": "type://HttpRequest#of(RequestHeaders,Publisher,HttpHeaders):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpRequest.html#of(com.linecorp.armeria.common.RequestHeaders,org.reactivestreams.Publisher,com.linecorp.armeria.common.HttpHeaders)"
          }}>{`type://HttpRequest#of(RequestHeaders,Publisher,HttpHeaders)`}</a>{` or
`}<a parentName="p" {...{
            "href": "type://HttpResponse#of(ResponseHeaders,Publisher,HttpHeaders):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#of(com.linecorp.armeria.common.ResponseHeaders,org.reactivestreams.Publisher,com.linecorp.armeria.common.HttpHeaders)"
          }}>{`type://HttpResponse#of(ResponseHeaders,Publisher,HttpHeaders)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3959"
          }}>{`#3959`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4727"
          }}>{`#4727`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`New API for Multipart Headers`}</strong>{`: You can now retrieve headers from a multipart request in an annotated service
using `}<a parentName="p" {...{
            "href": "type://MultipartFile#headers():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/multipart/MultipartFile.html#headers()"
          }}>{`type://MultipartFile#headers()`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5106"
          }}>{`#5106`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Access RequestLogProperty Values More Easily`}</strong>{`: `}<a parentName="p" {...{
            "href": "type://RequestLogAccess#getIfAvailable()"
          }}>{`type://RequestLogAccess#getIfAvailable()`}</a>{`
has been introduced, which allows users to access a `}<a parentName="p" {...{
            "href": "type://RequestLogProperty:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLogProperty.html"
          }}>{`type://RequestLogProperty`}</a>{` immediately if available. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4956"
          }}>{`#4956`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4966"
          }}>{`#4966`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Keep an Idle Connection Alive on PING`}</strong>{`: The `}<inlineCode parentName="p">{`keepAliveOnPing`}</inlineCode>{` option has been introduced. Enabling this option will keep
an idle connection alive when an HTTP/2 PING frame or `}<inlineCode parentName="p">{`OPTIONS * HTTP/1.1`}</inlineCode>{` is received. The option can be configured
by `}<a parentName="p" {...{
            "href": "type://ServerBuilder#idleTimeout(Duration,boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html#idleTimeout(java.time.Duration,boolean)"
          }}>{`type://ServerBuilder#idleTimeout(Duration,boolean)`}</a>{` or `}<a parentName="p" {...{
            "href": "type://ClientFactoryBuilder#idleTimeout(Duration,boolean):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html#idleTimeout(java.time.Duration,boolean)"
          }}>{`type://ClientFactoryBuilder#idleTimeout(Duration,boolean)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4794"
          }}>{`#4794`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4806"
          }}>{`#4806`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Create a StreamMessage from Future`}</strong>{`: You can now easily create a `}<a parentName="p" {...{
            "href": "type://StreamMessage:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/stream/StreamMessage.html"
          }}>{`type://StreamMessage`}</a>{` from a `}<inlineCode parentName="p">{`CompletionStage`}</inlineCode>{`
using `}<a parentName="p" {...{
            "href": "type://StreamMessage#of(CompletionStage%3C?"
          }}>{`type://StreamMessage#of(CompletionStage<?`}</a>{`)>. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/4995"
          }}>{`#4995`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`More Shortcuts for PrometheusExpositionService`}</strong>{`: You can now create a `}<a parentName="p" {...{
            "href": "type://PrometheusExpositionService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/metric/PrometheusExpositionService.html"
          }}>{`type://PrometheusExpositionService`}</a>{` without
specifying the default `}<inlineCode parentName="p">{`CollectorRegistry`}</inlineCode>{` explicitly. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/5134"
          }}>{`#5134`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`The number of event loops is equal to the number of cores by default when `}<inlineCode parentName="li">{`io_uring`}</inlineCode>{` is used as the transport type. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5089"
        }}>{`#5089`}</a></li>
      <li parentName="ul">{`You can now customize error responses when a service for a request is not found
using `}<a parentName="li" {...{
          "href": "type://ServiceErrorHandler#renderStatus()"
        }}>{`type://ServiceErrorHandler#renderStatus()`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4996"
        }}>{`#4996`}</a></li>
      <li parentName="ul">{`Redirection for a trailing slash is done correctly even if a reverse proxy rewrites the path. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4994"
        }}>{`#4994`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{` now tries to guess the correct route behind a reverse proxy. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4987"
        }}>{`#4987`}</a></li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`RetentionPolicy`}</inlineCode>{` of `}<a parentName="li" {...{
          "href": "type://@UnstableApi:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/annotation/UnstableApi.html"
        }}>{`type://@UnstableApi`}</a>{` annotation is now `}<inlineCode parentName="li">{`CLASS`}</inlineCode>{` so that
bytecode analysis tools can detect the declaration and usage of unstable APIs. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5131"
        }}>{`#5131`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
        }}>{`type://GrpcService`}</a>{` now returns an `}<inlineCode parentName="li">{`INTERNAL`}</inlineCode>{` error code if an error occurs while serializing gRPC metadata. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4625"
        }}>{`#4625`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4686"
        }}>{`#4686`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://DnsCache:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/DnsCache.html"
        }}>{`type://DnsCache`}</a>{` now allows zero TTL for resolved DNS records. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5119"
        }}>{`#5119`}</a></li>
      <li parentName="ul">{`Armeria's DNS resolver doesn't cache a DNS whose query was timed out. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5117"
        }}>{`#5117`}</a></li>
      <li parentName="ul">{`Fixed a bug where headers could be written twice if `}<inlineCode parentName="li">{`Content-Length`}</inlineCode>{` was exceeded during HTTP/2 cleartext upgrade. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5113"
        }}>{`#5113`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ServiceRequestContext#localAddress():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#localAddress()"
        }}>{`type://ServiceRequestContext#localAddress()`}</a>{` and `}<a parentName="li" {...{
          "href": "type://ServiceRequestContext#remoteAddress():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html#remoteAddress()"
        }}>{`type://ServiceRequestContext#remoteAddress()`}</a>{` now return
correct values when using domain sockets in abstract namespace. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5096"
        }}>{`#5096`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`armeria-logback12`}</inlineCode>{`, `}<inlineCode parentName="li">{`armeria-logback13`}</inlineCode>{`, and `}<inlineCode parentName="li">{`armeria-logback14`}</inlineCode>{` have been introduced for better
compatibility with `}<a parentName="li" {...{
          "href": "https://logback.qos.ch/"
        }}>{`Logback`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5045"
        }}>{`#5045`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5079"
        }}>{`#5079`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5078"
        }}>{`#5078`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5077"
        }}>{`#5077`}</a></li>
      <li parentName="ul">{`You can now use either an inline debug form or a modal debug form when using `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5072"
        }}>{`#5072`}</a></li>
      <li parentName="ul">{`When using Spring integrations, even if `}<inlineCode parentName="li">{`internal-services.port`}</inlineCode>{` and `}<inlineCode parentName="li">{`management.server.port`}</inlineCode>{`
are set to the same value internal services are bound to the port only once. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4796"
        }}>{`#4796`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5022"
        }}>{`#5022`}</a></li>
      <li parentName="ul">{`Exceptions that occurred during a TLS handshake are properly propagated to users. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4950"
        }}>{`#4950`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://AggregatedHttpObject#content(Charset):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/AggregatedHttpObject.html#content(java.nio.charset.Charset)"
        }}>{`type://AggregatedHttpObject#content(Charset)`}</a>{` now respects the `}<inlineCode parentName="li">{`charset`}</inlineCode>{` attribute in the
`}<inlineCode parentName="li">{`Content-Type`}</inlineCode>{` header if available. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4931"
        }}>{`#4931`}</a>{`  `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4948"
        }}>{`#4948`}</a></li>
      <li parentName="ul">{`Routes with dynamic predicates are not incorrectly cached anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4927"
        }}>{`#4927`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4934"
        }}>{`#4934`}</a></li>
    </ul>
    <h2 {...{
      "id": "-documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-documentation",
        "aria-label": " documentation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📃 Documentation`}</h2>
    <ul>
      <li parentName="ul">{`A new page has been added which describes how to integrate Armeria with Spring Boot. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4670"
        }}>{`#4670`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4957"
        }}>{`#4957`}</a></li>
      <li parentName="ul">{`Documentation on how `}<a parentName="li" {...{
          "href": "type://Flags:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Flags.html"
        }}>{`type://Flags`}</a>{` work in Armeria has been added. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/4870"
        }}>{`#4870`}</a></li>
      <li parentName="ul">{`A new example on how to use `}<a parentName="li" {...{
          "href": "https://github.com/mscheong01/krotoDC"
        }}>{`krotoDC`}</a>{` with Armeria has been added. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5092"
        }}>{`#5092`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`toStringHelper()`}</inlineCode>{` method in `}<a parentName="li" {...{
          "href": "type://DynamicEndpointGroup:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/endpoint/DynamicEndpointGroup.html"
        }}>{`type://DynamicEndpointGroup`}</a>{` has been replaced
with `}<inlineCode parentName="li">{`toString(Consumer)`}</inlineCode>{` to avoid exposing an internal API in the public API. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5132"
        }}>{`#5132`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://HttpResponse#from(CompletionStage):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#from(java.util.concurrent.CompletionStage)"
        }}>{`type://HttpResponse#from(CompletionStage)`}</a>{` and its variants methods are deprecated. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/5075"
        }}>{`#5075`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "type://HttpResponse#of(CompletionStage):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/HttpResponse.html#of(java.util.concurrent.CompletionStage)"
            }}>{`type://HttpResponse#of(CompletionStage)`}</a>{` and its variants instead.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`gRPC-Java 1.56.0 → 1.57.2`}</li>
      <li parentName="ul">{`GraphQL Kotlin 6.5.2 → 6.5.3`}</li>
      <li parentName="ul">{`Guava 32.0.1-jre → 32.1.2-jre`}</li>
      <li parentName="ul">{`Jakarta Websocket 2.1.0 → 2.1.1`}</li>
      <li parentName="ul">{`Kafka client 3.4.0 → 3.4.1`}</li>
      <li parentName="ul">{`Kotlin 1.8.22 → 1.9.0`}</li>
      <li parentName="ul">{`Kotlin Coroutine 1.7.1 → 1.7.3`}</li>
      <li parentName="ul">{`Logback 1.4.7 → 1.4.11`}</li>
      <li parentName="ul">{`Micrometer 1.11.1 → 1.11.3`}</li>
      <li parentName="ul">{`Netty 4.1.94.Final → 4.1.96.Final`}</li>
      <li parentName="ul">{`Protobuf 3.22.3 → 3.24.0`}</li>
      <li parentName="ul">{`Reactor 3.5.7 → 3.5.8`}</li>
      <li parentName="ul">{`Resilience4j 2.0.2 → 2.1.0`}</li>
      <li parentName="ul">{`Resteasy 5.0.5.Final → 5.0.7.Final`}</li>
      <li parentName="ul">{`Sangria 4.0.0 → 4.0.1`}</li>
      <li parentName="ul">{`scala-collection-compat 2.10.0 → 2.11.0`}</li>
      <li parentName="ul">{`Spring 6.0.9 → 6.0.11`}</li>
      <li parentName="ul">{`Spring Boot 2.7.12 → 2.7.14, 3.1.0 → 3.1.1`}</li>
      <li parentName="ul">{`Tomcat 10.1.10 → 10.1.12`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['Dogacel', 'KarboniteKream', 'Kyoungwoong', 'aki-s', 'anuraaga', 'baezzys', 'ceki', 'chrisryan10', 'ghkim3221', 'heowc', 'hyperxpro', 'ikhoon', 'jrhee17', 'marcingrzejszczak', 'minwoox', 'mscheong01', 'my4-dev', 'mynameisjwlee', 'r3mariano', 'ribafish', 'sh-cho', 'ta7uw', 'tomatophobia', 'trustin', 'wreulicke', 'yunjoopark']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      